import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import Client from '@/modules/api/libraries/Client'
import localforage from 'localforage';

import {
  SET_VERSION,
  SET_FIXED,
  SET_LANGUAGE,
  SET_URL,
  SET_TOKEN
} from './mutation-types'

import authentication from './modules/authentication.js';

import onLogin from '@/app/events/login.js';
import onSetUrl from '@/app/events/setUrl.js';

const LOCAL_STORAGE_KEY = 'phi.app';

export default new Vuex.Store({
  modules: {
    authentication
  },

  state: {
    version: null,
    isFixed: false,  // 'isFixed' indica que es una aplicacion custom y que no se debe permitir cambio de codigo de institucion

    language: null,
    url: null,
    token: null,
    user: null,

    httpClient: null,
  },

  mutations: {
    [SET_VERSION](state, version) {
      state.version = version;
    },

    [SET_FIXED](state, isFixed) {
      state.isFixed = isFixed;
    },

    [SET_LANGUAGE](state, language) {
      state.language = language;
    },

    [SET_URL](state, url) {
      state.url = url;
      state.httpClient = new Client(url);

      let v3Url = url.replace('.api', '');
      if (v3Url == url) {
        v3Url = 'http://phidias.local';
      }
      state.httpClient.hosts = {
        v3: new Client(v3Url)
      };

    },

    [SET_TOKEN](state, token) {
      state.token = token;
      if (state.httpClient) {
        let decoded = state.httpClient.setToken(token);
        state.user = decoded.payload;

        state.httpClient.hosts.v3.setToken(token);

      } else {
        state.user = null;
      }
    }
  },

  actions: {
    async initialize(context, { language, url, token }) {
      /* Ensure a language */
      if (!language) {
        language = window.navigator.userLanguage || window.navigator.language; // Use browser language
      }

      language && context.commit(SET_LANGUAGE, language);

      if (url) {
        context.commit(SET_URL, url);
        await onSetUrl(url);
      }

      if (token) {
        context.commit(SET_TOKEN, token);
        await onLogin(token);
      }

      // Suscripcion a eventos globales
      this.subscribe((mutation, state) => {
        switch (mutation.type) {
          case SET_URL:
            onSetUrl(mutation.payload);
            break;

          case SET_TOKEN:
            onLogin(mutation.payload);
            break;
        }
      });
    },

    async wakeup(context) {
      let initialData = {
        language: null,
        url: null,
        token: null
      };


      // Buscamos datos de APP en el local storage
      let storedData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

      if (storedData) {
        if (storedData.language) {
          initialData.language = storedData.language;
        }

        if (storedData.url && !initialData.url) {
          initialData.url = storedData.url;
        }

        if (storedData.token) {
          initialData.token = storedData.token;
        }
      }

      await context.dispatch('initialize', initialData);


      // now we subscribe to changes in the store to handle persistence :)
      // (with a 420ms debounce)
      let storageTimer = null;
      this.subscribe((mutation, state) => {
        switch (mutation.type) {
          case SET_LANGUAGE:
          case SET_URL:
          case SET_TOKEN:
            clearTimeout(storageTimer);
            storageTimer = setTimeout(() => {
              let persistentData = {
                language: state.language,
                url: state.url,
                token: state.token
              };

              localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(persistentData));

              // Localforage stores a copy to be used by serviceworkers
              try {
                localforage.setItem(LOCAL_STORAGE_KEY, persistentData);
              } catch (err) {
                console.warn('cannot store data in localforage');
              }
            }, 420);

            break;
        }
      });
    }
  }
})