export default {
  push: null,
  listeners: [],

  onNotification(listener) {
    this.listeners.push(listener);
  },

  subscribe() {
    return new Promise((resolve, reject) => {


      this.push = PushNotification.init({
        android: {
            icon: "icon",
            iconColor: "#00000000",
            sound:true,
            vibrate:true,
            forceShow:true
        },
        ios: {
            alert: true,
            badge: true,
            sound: true
        }
      });

      this.push.on('registration', data => {
        if (!window.device || !window.device.platform) {
          reject();
        }

        let device = {
          token: data.registrationId,
          platform: window.device.platform == 'Android' ? 'gcm' : window.device.platform,
          model: window.device.model,
          uuid: window.device.uuid
        };

        resolve(device);
      });

      this.push.on('notification', notification => {
        
        this.listeners.forEach(listener => listener(notification));
      });

      this.push.on('error', function (e) {
        reject(e.message);
      });

    });
  },

  unsubscribe() {
    return new Promise((resolve, reject) => {
      this.push.unregister(resolve, reject);
    });
  }
}