import store from '@/app/store';
import apiApp from '@/app/api/app.js';
import i18n from '@/modules/i18n/singleton';
import settings from '@/app/singletons/settings.js';

const LOCAL_STORAGE_KEY = 'phi.app.settings';

/*
Acciones a ejecutar cuando se hace ESTABLECE LA URL BASE
*/
export default async function (url) {

  // Hoja de estilos custom
  let link = document.createElement('link');
  link.href = url + '/1/app/css';
  link.type = 'text/css';
  link.rel = 'stylesheet';
  document.head.appendChild(link);

  let appData;
  try {
    appData = await apiApp(store.state.httpClient).getInitialData();
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(appData));
  } catch (err) {
    console.warn('Failed to obtain initial data', err);
    appData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

    if (!appData) {
      // No se obtuvieron datos de inicio en el api, ni habia copia local.
      // Error fatal.  No se puede usar modo offline tampoco :(
      alert('Cannot connect to Phidias');
      throw 'Cannot connect to Phidias';
    }

    console.warn('Fetched from localStorage.  Assuming offline mode', appData);
  }

  if (appData.settings) {
    Object.assign(settings, appData.settings);
  }

  if (appData.i18n) {
    i18n.override(appData.i18n);
  }
}