import io from 'socket.io-client';

export default class {
  constructor(url, token, room, listeners = null, listenerBind = undefined) {
    this.url = url;
    this.token = token;
    this.room = room;
    this.listeners = {};
    this.listenerBind = listenerBind;

    if (listeners) {
      for (let eventName in listeners) {
        if (listeners.hasOwnProperty(eventName) && typeof listeners[eventName] == 'function') {
          this.on(eventName, listeners[eventName]);
        }
      }
    }

    this.socketIoClient = io(this.url, {
      query: {
        token: this.token,
        room: this.room
      }
    });

    this.socketIoClient.on('phidias-event', (event) => this.handleEvent(event));
  }

  on(eventName, handler) {
    if (typeof this.listeners[eventName] == 'undefined') {
      this.listeners[eventName] = [];
    }
    this.listeners[eventName].push(handler);
  }

  handleEvent(event) {
    if (typeof this.listeners[event.event] == 'undefined') {
      return;
    }

    this.listeners[event.event].forEach(handler => {
      handler.call(this.listenerBind, event);
    });
  }

  emit(eventName, eventData) {
    this.socketIoClient.emit('phidias-emit', { event: eventName, data: eventData, room: this.room });
  }

  broadcast(eventName, eventData) {
    this.socketIoClient.emit('phidias-broadcast', { event: eventName, data: eventData, room: this.room });
  }

  disconnect() {
    this.socketIoClient.close();
  }
}