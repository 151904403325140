export default httpClient => ({

  login(username, password) {
    return httpClient.fetch("oauth/token", {
      method: "post",
      headers: { Authorization: 'Basic ' + btoa(username + ':' + password) },
      body: { grant_type: "client_credentials" }
    })
      .then(response => response.json())
  },

  google(accessCode) {
    return httpClient.post('oauth/google', { code: accessCode });
  }

});