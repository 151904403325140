import store from '@/app/store';

import notifications from '@/app/singletons/notifications';

import $nav from '@/app/navigation/singleton.js';
import apiNavigation from '@/app/api/navigation';
import apiNotifications from '@/app/api/notifications';

import onNotification from '@/app/events/notification.js';

/*
Acciones a ejecutar cuando se hace LOGIN
Nota: Siempre que se inicializa la app y hay datos de sesion en localStorage tambien se considera login
*/
export default async function (token) {

  // Registrar con servicio de notificaciones
  notifications.subscribe()
    .then(device => apiNotifications(store.state.httpClient).registerDevice(store.state.user.id, device))
    .catch(error => console.warn("app/events/login: could not subscribe to notifications", error));

  // Establecer evento global para recibir notificaciones
  notifications.onNotification(onNotification);

  // Navegacion: cargar pestañas iniciales
  try {
    let navSettings = await apiNavigation(store.state.httpClient).getInitialTabs();
    if (Array.isArray(navSettings.tabs)) {
      navSettings.tabs.forEach(tab => $nav.pushTab(Object.assign(tab, { fixed: true })));
    }
  } catch (err) {
    console.warn('Filed to obtain initial tabs', err);
  }
}