import apiAuthentication from '@/app/api/authentication.js';
import { SET_TOKEN } from '@/app/store/mutation-types';

export default {

  actions: {
    setToken(context, { token }) {
      context.commit(SET_TOKEN, token);
      return context.rootState.user;
    },

    login(context, { username, password }) {
      if (!context.rootState.httpClient) {
        return;
      }

      return apiAuthentication(context.rootState.httpClient)
        .login(username, password)
        .then(async (response) => {
          context.commit(SET_TOKEN, response.access_token);
          return context.rootState.user;
        });
    },

    logout(context) {
      context.commit(SET_TOKEN, null);
    }
  }
};