import store from '@/app/store';
import settings from '@/app/singletons/settings.js';
import ioClient from '@/modules/io/client.js';

export default {
  inject: {
    $ioToken: {
      default: () => store.state.token
    }
  },

  data() {
    return {
      '$io': null
    }
  },

  mounted() {
    if (typeof this.$options.$io != 'object') {
      console.error('useIo: No $io option declared in component');
      return;
    }

    if (!this.$options.$io.room) {
      return;
    }

    let room;
    if (typeof this.$options.$io.room == 'function') {
      room = this.$options.$io.room.call(this);
    } else {
      room = this.$options.$io.room;
    }

    if (!room) {
      console.error('useIo: Cannot determine \'room\'');
      return;
    }

    this.$ioConnect(room);
  },

  methods: {
    $ioConnect(room) {
      let host = settings.get('io.host', 'https://io.phidias.co/default');
      this.$io = new ioClient(host, this.$ioToken, room, this.$options.$io.events, this);
    }
  },

  beforeDestroy() {
    if (!this.$io) {
      return;
    }

    this.$io.disconnect();
    this.$io = null;
  }
};